import React from 'react'
import "./Ourtwo.css"

import second from "./second.png"
function Ourtwo() {
  return (
    <div className='ourtwo'>
      <div className="second-img">
        <img src={second} alt="" height={400} />
      </div>
      <div className="second-text">
    <h1>Personal Training</h1>
    <h3>Tailored Fitness Just for You
</h3>
<p>Unlock your potential with Personal Training. Our experts begin with detailed movement and nutrition assessments to craft a fully personalized fitness plan tailored to your goals.
</p>
{/* <button>Know more</button> */}
      </div>
    </div>
  )
}

export default Ourtwo
