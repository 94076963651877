import React from 'react'
import "./JoinUs.css"
import j1 from "./j1.png"
import { BsArrowRight } from 'react-icons/bs'
const JoinUs = () => {
  return (
    <div className='join-us-area'>
      <div className="text-area-join">
        <strong>Join Us</strong>
        <h2>Are You Ready to Step Outside <br/> and Get Fit?</h2>
     <div className="j-btn">
        <span>Get Started Today!</span>
       <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE">
        <button>Know more <BsArrowRight/></button>
        </a>
     </div>
      </div>
      <div className="img-area-join">
        <img src={j1} height={400} alt="" />
      </div>
    </div>
  )
}

export default JoinUs
