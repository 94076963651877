import React from 'react'
import g1 from "./img/g1.png"
import "./Group.css"
import { BsArrowRight } from 'react-icons/bs'
const Group = () => {
  return (
    <div className='grp'>
        <div className="heading">
      <h4>Group Sessions</h4>
      </div>
      <div className="orange">
        <div className="bg-abs">
        <img src={g1} alt="" />
        <div>
            <h2>Adults</h2>
            <p>Ready to Transform Your Fitness? Choose Your Path</p>
        </div>
      </div>   
      <h1 style={{height:"200px"}}>
        </h1>  
        <div className="org-card-area">
        <div className="org-card">
            <h3>Low to Medium Intensity Programs</h3>
            <span>Ideal for those new to fitness or seeking a sustainable and gentle way to stay active. These sessions are designed to build core strength, flexibility, and overall fitness without straining the body, ensuring everyone feels comfortable and included.</span>
            <ul>
                <li><strong>Duration:</strong> 30 minutes</li>
                <li><strong>Days:</strong> Monday, Tuesday, Wednesday, Thursday and Friday</li>
                <li><strong>Timing:</strong> 7 - 7:30AM | 7:30 - 8AM | 8 - 8:30AM | 8:30 - 9AM</li>
            </ul>
            <div className="org-btn">
            <p>Sign Up for Low to Medium Intensity</p>
            <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE">
               <button>Know More <BsArrowRight/></button> </a>

            </div>
            
        </div>
        <div className="org-card">
            <h3>High-intensity programs</h3>
            <span>These programs are designed for those who wish to challenge themselves and elevate their fitness level. They involve dynamic movements that improve cardiovascular health, endurance, and muscle strength. This program combines weightlifting, gymnastics, callisthenics, mobility, and endurance training.</span>
            <ul>
                <li><strong>Duration:</strong> 60 minutes</li>
                <li><strong>Days:</strong> Monday, Tuesday, Wednesday, Thursday and Friday</li>
                <li><strong>Timing:</strong> 7:00 - 8:00AM | 8:00 - 9:00AM | 6:00 - 7:00PM  7:00 - 8:00PM</li>
            </ul>
            <div className="org-btn">
            <p>Sign Up for MEDIUM to High Intensity</p>
            <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE">
          <button> Know More <BsArrowRight/></button>
          </a>
            </div>
            
        </div>
      </div>
      </div>
    
      
       
    </div>
  )
}

export default Group
