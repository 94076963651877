import c1 from "./img/c1.png"
import c2 from "./img/c2.png"
import c3 from "./img/c3.png"
import c4 from "./img/c4.png"
import c5 from "./img/c5.png"

export const Data = [
    {
        img:c1, 
        text: 'weightlifting'
    },
    {
        img:c2, 
        text: 'gymnastics'
    },
    {
        img: c3, 
        text: 'calisthenics'
    },
    {
        img:c4, 
        text: 'mobility'
    },
    {
        img:c5, 
        text: 'endurance'
    },
]