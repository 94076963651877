import React from 'react'
import "./Main.css"
import{ Data} from "./Data"
import MainTamp from './mainTamp'
import { BsArrowRight } from 'react-icons/bs'
function Main() {
  return (
    <div className='Main-func'>
       
      <h2>Features of Our Personal Training</h2>
      <div className="temp-area-func">
      {
        Data.map((data=>(
        <MainTamp img={data.img}
        heading={data.heading} 
        text1={data.text1}
        text2={data.text2}
        /> 
        )))
      }</div>
    <div className='func-btn-area'>
      <h3>Start Your Transformation: <span style={{color:"#4E62D6"}}> Book Your First Session</span></h3>
      <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"> 
       <button>Know more <BsArrowRight/></button>
       </a>
      </div>
    </div>
  )
}

export default Main
