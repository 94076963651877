import React from 'react'
import g1 from "./img/g1.png"
import "./Kids.css"
import { BsArrowRight } from 'react-icons/bs'
const Kids = () => {
  return (
    <div className='kid-grp-area'>
     <div className="kid-orange-area">
     <h1 style={{height:"200px"}}>
        </h1>
      <img src={g1} alt="" />
      <div className="kid-or-area">
      <div className='koa'>
            <h3>Kids</h3>
            <p>Help Your Kids Fall in !<br/> Love with Movement</p>
        </div>
        <div className="org-card">
            <h3>Building Healthy Futures</h3>
            <span>Our children's programs are meticulously crafted to foster a love for movement from an early age. We infuse fitness with fun and engagement, aiding kids in developing healthy habits and physical confidence that will serve them well into adulthood, ensuring their future health and well-being.</span>
            <ul>
                <li><strong>Duration:</strong> 60 minutes</li>
                <li><strong>Days:</strong> Monday, Tuesday, Wednesday, Thursday and Friday</li>
                <li><strong>Timing:</strong> 4:00 - 5:00PM | 5:00-6:00PM</li>
            </ul>
            <div className="org-btn">
            <p>Enroll Your Child Today</p>
            <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"> 
            <button>Enroll <BsArrowRight/></button>
            </a>
            </div>
            
        </div>
     </div>
     </div>

    </div>
  )
}

export default Kids
