import React from 'react'
import "./Combines.css"
import { Data } from './data'
const Combines = () => {
  return (
    <div className='combines'>
      <h1>Our program combines </h1>
      <div className="com-area">
      {
        Data.map(data => (
            <div className='comb'  key={data.img}>
                <img src={data.img} height={60} alt="" />
                <h3>{data.text}</h3>
            </div>
        ))
    }
      </div>
    </div>
  )
}

export default Combines
