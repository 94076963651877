import m1 from "./img/m1.png"
import m2 from "./img/m2.png"
import m3 from "./img/m3.png"
import m4 from "./img/m4.png"
export const Data=[
{
img: m1,
heading:"Custom Assessments" ,
text1: "Get a holistic view of your fitness and dietary habits for targeted improvements.",

},
{
img: m2,
heading:"Personalized  Plans" ,
text1: "Designed specifically for your needs, whether it's losing weight, building strength, or increasing flexibility.",

},
{
img: m3,
heading:"One-on-One Attention" ,
text1: "·Maximize every workout with the guidance and expertise of our dedicated trainers.",

},
{
    img: m4,
    heading:"Continuous Support" ,
    text1: "Regular updates and motivational boosts to keep you focused and on track.",
    
    },
]