import React from 'react'
import "./Our.css"
import pro from "./pro.png"
import { Link } from 'react-router-dom'
function Our() {
  return (
    <div className='our'>   
      <div className="our-text">
        <p> At <strong className='func'>X60</strong> we believe in the power of nature and its profound impact on our health. Our fitness studio is nestled outdoors, encouraging synchronization with the environment as you train. We focus on functional movements that promote strength, flexibility, and balance, enhancing your health in the most natural setting. Alongside our fitness philosophy, we emphasize the importance of nutrition from real food, guiding you to nourish your body with the best that nature offers.</p>
        {/* <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"> 
        <button>Know more</button>
        </a> */}
      </div>
      <div className="our-img">
        <img src={pro} alt="" height={400}/>
      </div>
    </div>
  )
}

export default Our
