
import './App.css';
import Home from "./Home/Home"
import Navbar from "./Navbar/Navbar"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Media from './Media/Media';
import About from './About/About';
import Footer from "./Footer/Footer"
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/media' element={<Media/>} />
        <Route path='/about' element={<About/>} />
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
