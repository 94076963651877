import React from 'react'
import g1 from "./img/g1.png"
import "./Seniors.css"
import { BsArrowRight } from 'react-icons/bs'
const Seniors = () => {
  return (
    <div className='Seniors-grp-area'>
     <div className="Seniors-orange-area">
     <h1 style={{height:"200px"}}>
        </h1>
      
      <div className="Seniors-or-area">
      <div className='koa'>
            <h3>Seniors</h3>
            <p>Maintain Your Independence <br/>with Tailored Fitness!</p>
        </div>
        <div className="org-card">
            <h3>Senior Wellness</h3>
            <span>We offer specially tailored programs for seniors focusing on mobility, balance, and strength. We aim to help seniors maintain their independence and enjoy an active lifestyle.</span>
            <ul>
                <li><strong>Duration:</strong> 30 minutes</li>
                <li><strong>Days:</strong> Monday, Tuesday, Wednesday, Thursday and Friday</li>
                <li><strong>Timing:</strong> 7:00 - 7:30AM | 7:30 - 8:00AM | 8:00 - 8:30AM 8:30-9:00AM</li>
            </ul>
            <div className="org-btn">
            <p>Register for Senior Programs</p>
            <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"> 
             <button>Signup <BsArrowRight/></button>
              </a>
            </div>
            
        </div>
     </div>
     <img src={g1} alt="" />
     </div>

    </div>
  )
}

export default Seniors
