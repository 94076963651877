import React from 'react'
import "./Other.css"
// import background from "./img/background.png"
import { Data } from './Data'
import { BsArrowRight } from 'react-icons/bs'
function Other() {
  return (
    <div className='StandOut'>
      <h2>Other Programs</h2>
      <div className='stand-card-area'>
      {
      Data.map((data, index) => (
        <div className="standout-card" key={index}>
          <img src={data.img} alt="" />
          <div className="standout-txt">
            <h3>{data.heading}</h3>
            <p>{data.text1}</p>
            <strong>{data.text2}</strong>
            <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"> 
              <button>{index === 3 ? 'Signup' : 'Know more'} <BsArrowRight/></button>
            </a>
          </div>
        </div>
      ))
      
      }
      </div>
    </div>
  )
}

export default Other
