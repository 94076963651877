import m1 from "./img/m1.png"
import m2 from "./img/m2.png"
import m3 from "./img/m3.png"
import m4 from "./img/m4.png"
export const Data=[
{
img: m1,
heading:"Weekend Workshops" ,
text1: "Our mobility workshop will help you increase flexibility and prevent injuries. Dive into calisthenics to enhance your strength through precise bodyweight training, or explore gymnastics to improve your coordination and agility. ",
text2:"Discover Our Corporate Wellness Workshops"
},
{
img: m2,
heading:"School Programs" ,
text1: "We collaborate with schools to introduce functional fitness and nutrition education to students, fostering a healthy lifestyle from a young age.",
text2:"Learn About Our School Programs"
},
{
img: m3,
heading:"Corporate Workshops" ,
text1: "Our corporate workshops are designed to bring health and wellness directly to the workplace. We offer sessions that help employees reduce stress, improve health, and enhance productivity through fitness and proper nutrition.",
text2:"Discover Our Corporate Wellness Workshops"
},
{
    img: m4,
    heading:"Webinars" ,
    text1: "Dive deep into fitness, nutrition, and wellness topics with our expert-led webinars. They are perfect for those looking to expand their knowledge and integrate healthier choices into their daily lives.",
    text2:"Sign Up for Our Next Webinar"
    }
]